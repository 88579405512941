'use client';

import clsx from 'clsx';
import styles from './Card.module.scss';
import type { CardBaseProps } from './Card';

type Props = CardBaseProps & {
  /**
   * The alignment of the content inside the card
   * @default 'start'
   */
  alignment?: 'start' | 'center' | 'end';
};

export const CardFooter = ({
  alignment = 'start',
  className,
  children,
}: Props): JSX.Element => {
  const alignmentConfig = {
    start: 'card-start',
    center: 'card-center',
    end: 'card-end',
  };

  return (
    <div
      className={clsx(
        styles['card-footer'],
        styles[alignmentConfig[alignment]],
        className,
      )}
    >
      {children}
    </div>
  );
};
