import { createContext, useContext } from 'react';
import type { Page as PageFragment } from '../../contentful/generated/graphql';
import type { ReactNode } from 'react';

type PageContextType = {
  pages: NonNullable<PageFragment[]>;
  childPages: NonNullable<PageFragment[]>;
  currentPage?: PageFragment;
  parentPage?: PageFragment | null;
  childPage?: PageFragment | null;
  isLayout: boolean;
};

const PagesContext = createContext<PageContextType>({
  pages: [],
  childPages: [],
  isLayout: false,
});

type PagesProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pages: any[];
  children: ReactNode;
  isLayout?: boolean;
};

export const PagesProvider = ({
  children,
  pages,
  isLayout = false,
}: PagesProviderProps): JSX.Element => {
  const currentPage = pages[0];
  const parentPage = currentPage?.parent;
  const childPage = pages[1];
  const childPages = pages?.slice(1, pages.length) || [];

  return (
    <PagesContext.Provider
      value={{
        pages,
        currentPage,
        parentPage,
        childPage,
        childPages,
        isLayout,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
};

export const usePages = (): PageContextType => useContext(PagesContext);
