import styles from './theme.module.scss';

export type BackgroundStyle = 'default' | 'blur' | 'transparent';

export const backgroundStyleClass = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  backgroundStyle?: (string & {}) | BackgroundStyle | null,
): string | undefined => {
  if (backgroundStyle === 'blur') {
    return styles.blurBackground;
  }
};
