import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import RichText from '@quno/patient-journey/src/components/ui/ContentBlocks/RichText';
import { useTemplateWords } from '@quno/patient-journey/src/hooks/useTemplateWords';
import { Card } from '../Card';
import styles from './ProductCard.module.scss';
import type { CardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type ProductCardProps = {
  cid: string;
  image?: JSX.Element | null;
  title?: string | null;
  footer?: string | null;
};

export const ProductCard = ({
  cid,
  text,
  image,
  title,
  footer,
  backgroundColorOverwrite,
  darkModeEnabled,
}: CardProps & ProductCardProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });
  const { replaceTemplateWords } = useTemplateWords();

  return (
    <Card
      className={styles.item}
      footer={footer}
      backgroundColorOverwrite={backgroundColorOverwrite}
      darkModeEnabled={darkModeEnabled}
    >
      <div
        className={styles.imageWrapper}
        {...inspectorProps({ fieldId: 'primaryImage' })}
      >
        {image}
      </div>
      <h4 className={styles.title} {...inspectorProps({ fieldId: 'title' })}>
        {replaceTemplateWords(title!)}
      </h4>
      <div {...inspectorProps({ fieldId: 'description' })}>
        <RichText fields={text} noWrapper />
      </div>
    </Card>
  );
};
