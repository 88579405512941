import { Fragment } from 'react';
import type { ReactNode } from 'react';

export type AccordionProps = {
  children: ReactNode[];
};

export const Accordion = ({ children }: AccordionProps): JSX.Element => {
  return (
    <div>{children?.map((item, i) => <Fragment key={i}>{item}</Fragment>)}</div>
  );
};
