import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { AccordionItem } from '@quno/patient-journey/src/components/Accordion/AccordionItem';
import type { BlockAccordionItemFragment } from '../../generated/graphql-patient-journey';

export const BlockAccordionItem = (
  props: BlockAccordionItemFragment,
): JSX.Element => {
  const updatedEntity = useContentfulLiveUpdates(props);

  const { content, title } = updatedEntity;

  return (
    <AccordionItem
      cid={props.sys.id}
      title={title || ''}
      // eslint-disable-next-line
      // @ts-ignore
      content={content}
    />
  );
};
