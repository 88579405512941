import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { FunnelCalendarBooking } from '@quno/patient-journey/src/components/CalendarBooking/FunnelCalendarBooking';
import type { BlockCalendarBookingFragment } from '../../generated/graphql-patient-journey';

export const BlockCalendarBooking = (
  props: BlockCalendarBookingFragment,
): JSX.Element | null => {
  const updatedEntity = useContentfulLiveUpdates(props);

  const eventSetupId = updatedEntity.eventSetupId as string;
  const funnelId = updatedEntity.funnelId as string;

  return (
    <FunnelCalendarBooking
      cid={props.sys.id}
      eventSetupId={eventSetupId}
      funnelId={funnelId}
    />
  );
};
