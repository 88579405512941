import clsx from 'clsx';
import styles from './Card.module.scss';
import { CardContent } from './CardContent';
import { CardFooter } from './CardFooter';
import { CardMedia } from './CardMedia';
import type { Orientation } from '../types/qds';
import type { MouseEventHandler, ReactNode, CSSProperties } from 'react';

/**
 * EXAMPLE:
 *
 *
        <Card orientation="horizontal">
          <Card.Media
            alt="media test"
            image="https://www.qunomedical.com/_next/image?url=%2Fv2%2Fimages%2Fpm_j.jpg&w=1920&q=75"
          >
            test card media content
          </Card.Media>
          <Card.Content>test card footer content</Card.Content>
        </Card>
 */

export type CardBaseProps = {
  /**
   * If `true`, the image will serve as background for the entire card.
   * @default false
   */
  full?: boolean;
  /**
   * Classname(s) added to the respective component
   * @default ''
   */
  className?: string;
  /**
   * The content of the card
   */
  children?: ReactNode;
};

type Props = CardBaseProps & {
  /**
   * onClick handler
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
  /**
   * @default true
   */
  hoverable?: boolean;
  /**
   * The orientation in which the card will be displayed.
   * If `responsive` the orientation will change to horizontal on desktop
   * @default 'vertical'
   */
  orientation?: Orientation;
  style?: CSSProperties;
};

export const Card = ({
  children,
  full,
  hoverable = true,
  onClick,
  orientation = 'vertical',
  className,
  style,
}: Props): JSX.Element => {
  const orientationConfig = {
    horizontal: 'card-horizontal',
    responsive: 'card-responsive',
    vertical: 'card-vertical',
  };

  return (
    <div
      className={clsx(
        styles['card'],
        styles[orientationConfig[orientation]],
        full && styles['card-full-image'],
        hoverable && styles['card-hoverable'],
        className,
      )}
      onClick={onClick && onClick}
      style={style}
    >
      {children}
    </div>
  );
};

Card.Content = CardContent;
Card.Footer = CardFooter;
Card.Media = CardMedia;
