export const CURRENCY = process.env.FEATURE_MTA_MASTERCARD ? 'USD' : 'EUR';
export const CURRENCY_SYMBOL = process.env.FEATURE_MTA_MASTERCARD ? '$' : '€';

export enum PaymentMethod {
  BANK = 'Bank',
  PAYPAL = 'Paypal',
  STRIPE = 'Stripe',
}

export enum PaymentStatus {
  CANCELED = 'payment_canceled',
  SUCCEEDED = 'payment_succeeded',
}
