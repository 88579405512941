import { RenderType } from '../types/RenderType';

export type WithSlug = {
  slug?: string | null;
  parent?: WithSlug | null;
  render?: RenderType | null;
};

export const getPageInOrder = (
  page: WithSlug,
  pages: WithSlug[] = [],
): WithSlug[] => {
  if (page.parent && page.render !== RenderType.STANDALONE) {
    getPageInOrder(page.parent, pages);
  }

  pages.push(page);

  return pages;
};
