import { useState } from 'react';
import clsx from 'clsx';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import RichText from '../ui/ContentBlocks/RichText';
import Icon from '../ui/Icon';
import styles from './Accordion.module.scss';
import type { JsonRichText } from '@quno/patient-journey/types/Utilities';

export type AccordionItemProps = {
  cid: string;
  title: string;
  content?: Document | JsonRichText;
};

export const AccordionItem = ({
  cid,
  title,
  content,
}: AccordionItemProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });
  const [showItem, setShowItem] = useState(false);

  return (
    <div
      onClick={() => {
        setShowItem(!showItem);
      }}
      className={clsx(styles.item, styles.arrow, showItem && styles.arrowOpen)}
    >
      <h3 className={styles.title}>
        <span {...inspectorProps({ fieldId: 'title' })}>{title}</span>
        <Icon type="Chevron" color="#00BCB3" width={22} height={12} />
      </h3>
      {showItem && (
        <div
          className={styles.content}
          {...inspectorProps({ fieldId: 'content' })}
        >
          {/** eslint-disable-next-line
           * @ts-ignore  */}
          <RichText fields={content} noWrapper />
        </div>
      )}
    </div>
  );
};
