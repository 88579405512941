import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { Cta } from '@quno/patient-journey/src/components/Cta/Cta';
import type { CtaFragment } from '../../generated/graphql-patient-journey';

export const BlockCta = (props: CtaFragment): JSX.Element | null => {
  const updatedEntity = useContentfulLiveUpdates(props);

  const { text, link } = updatedEntity;

  const { action, funnel } = updatedEntity;
  const funnelUrn = funnel?.sys.urn;

  let funnelId = null;
  if (action === 'funnel' && funnelUrn) {
    const index = funnelUrn.lastIndexOf('/');
    funnelId = funnelUrn.substring(index + 1);
  }

  return (
    <Cta
      action={action}
      funnelId={funnelId}
      {...updatedEntity}
      text={text}
      link={link}
    />
  );
};
