import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import RichText from '@quno/patient-journey/src/components/ui/ContentBlocks/RichText';
import type { JsonRichText } from '@quno/patient-journey/types/Utilities';
import type { Document } from '@contentful/rich-text-types';
import type { CardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type TextCardProps = {
  cid: string;
  text: Document | JsonRichText;
  alignment?: 'left' | 'center' | 'right';
};

export const TextCard = ({
  cid,
  text,
  alignment = 'left',
  fontColorOverride,
  backgroundColorOverwrite,
  cardBorderRadius,
}: CardProps & TextCardProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });

  return (
    <div
      style={{
        textAlign: alignment,
        color: fontColorOverride || undefined,
        backgroundColor: backgroundColorOverwrite || 'transparent',
        borderRadius: cardBorderRadius || 0,
        padding: '1rem',
      }}
    >
      <div {...inspectorProps({ fieldId: 'description' })}>
        <RichText
          fields={text}
          noWrapper
          style={
            {
              color: fontColorOverride,
              '--list-style-color': fontColorOverride,
            } as React.CSSProperties
          }
        />
      </div>
    </div>
  );
};
