import { Image } from '@quno/patient-journey/src/components/Image/Image';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import type { AssetFragment } from '../generated/graphql';
import type { ContentfulImageConfig } from '@quno/patient-journey/src/utils/contentful';

export const renderImage = (
  asset?: AssetFragment | null,
  options?: {
    container?: boolean;
    fill?: boolean;
    maxWidth?: number;
    fit?: ContentfulImageConfig['fit'];
    focus?: ContentfulImageConfig['focus'];
    style?: React.CSSProperties;
  },
): JSX.Element => {
  const additionalProps = options?.container
    ? {
        containerHeight: '100%',
        containerWidth: '100%',
      }
    : {};

  /* const width =
    (asset?.width || 0) < (options?.maxWidth || 0)
      ? asset?.width
      : options?.maxWidth; */

  const isSvg = !!asset?.url?.includes('.svg');

  return (
    <Image
      src={asset?.url || ''}
      alt={asset?.title || ''}
      loader={createCloudimgLoader({
        fit: options?.fit,
        focus: options?.focus,
        isSvg,
      })}
      fill={options?.fill || false}
      width={asset?.width || undefined}
      height={asset?.height || undefined}
      style={{ ...options?.style }}
      {...additionalProps}
    />
  );
};
