import Head from 'next/head';

export type MetaTagsProps = {
  title?: string | null;
  description?: string | null;
  ogImage?: string | null;
  ogDescription?: string | null;
  favicon?: string | null;
};

export const MetaTags = ({
  title,
  description,
  ogImage,
  ogDescription,
  favicon,
}: MetaTagsProps): JSX.Element => (
  <Head>
    {!!title && <title>{title}</title>}

    {!!description && <meta name="description" content={description} />}
    {!!ogImage && <meta property="og:image" content={ogImage} />}
    {!!ogDescription && (
      <meta property="og:description" content={ogDescription} />
    )}
    {!!favicon && <link rel="icon" href={favicon} />}
  </Head>
);
