import type { LeadOrOpportunityResponse } from '@quno/patient-journey/src/services/lead-or-opportunity';

export const findLeadOrOpportunity = async (
  junoid: string,
): Promise<LeadOrOpportunityResponse | null> => {
  if (junoid) {
    const response = await fetch(`/api/lead-or-opportunity?junoId=${junoid}`);
    if (response) {
      const data = await response.json();
      if (data) {
        const { hasResult, result } = data;
        if (hasResult && result) {
          return result;
        }
      }
    }
  }

  return null;
};
