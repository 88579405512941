import { BaseContainer } from '@quno/patient-journey/src/components/Container/BaseContainer';
import type { ContainerProps } from '@quno/patient-journey/src/components/Container/container.config';

export const SwipeableContainer = ({
  children,
  scrollable,
  ...props
}: ContainerProps): JSX.Element => {
  return (
    <BaseContainer isSwipeContainer={scrollable} {...props}>
      {children}
    </BaseContainer>
  );
};
