import Button from '@quno/patient-journey/src/components/ui/Button';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import PatientManagerCard from '../PatientManagerComment/PatientManagerCard';
import styles from './ExistingQuoteModal.module.scss';
import type { Quote } from '@quno/patient-journey/types/Quote';

type Props = {
  quote: Quote | undefined;
  junoId: string;
};

export const ExistingQuoteModal = ({ quote, junoId }: Props): JSX.Element => {
  const { t, locale } = useTranslation(true);
  const { Account_Firstname__c, Account_Lastname_Initial__c, Id } = quote || {};

  const firstName = Account_Firstname__c?.toLowerCase() || '';
  const lastName = Account_Lastname_Initial__c?.[0]?.toLowerCase() || '';

  const name = [firstName, lastName].filter(Boolean).join('.');

  const quoteUrl = `/${locale}/quotes/${name}/${junoId}/${Id}`;

  return (
    <div className={styles.container}>
      <div className={styles.headline}>
        <strong>{`Hey ${quote?.Account_Firstname__c},`}</strong>
        <p>{t('existingJunoId.title')}</p>
      </div>

      <p className={styles.description}>{t('existingJunoId.description')}</p>

      <PatientManagerCard
        managerId={quote?.OwnerId}
        className={styles.manager}
      />

      <Button
        size="large"
        shadow={false}
        className={styles.button}
        fullWidth
        isLink
        href={quoteUrl}
      >
        {t('existingJunoId.button')}
      </Button>
    </div>
  );
};
