import NextImage from 'next/image';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
import styles from './Image.module.scss';
import type { ImageLoader, ImageProps as NextImageProps } from 'next/image';

export type ImageProps = {
  style?: React.CSSProperties;
  height?: number;
  width?: number;
  containerHeight?: string | null;
  containerWidth?: string | null;
  src: string;
  quality?: number;
  alt: string;
  loader?: ImageLoader;
  fill?: NextImageProps['fill'];
  caption?: string;
  link?: string;
  className?: string;
};

export const Image = ({
  style,
  fill,
  height,
  width,
  containerHeight,
  containerWidth,
  loader,
  quality = 75,
  className = '',
  caption,
  link,
  ...props
}: ImageProps): JSX.Element => {
  if (fill) {
    return (
      <div
        className={styles.imageWrapper}
        style={{
          height: containerHeight || 0,
          width: containerWidth || 0,
        }}
      >
        <NextImage
          className={className}
          quality={quality}
          fill
          loader={loader}
          style={style}
          {...props}
        />
        {!!caption && <div className={styles.alt}>{caption}</div>}
      </div>
    );
  }

  if (link) {
    return (
      <NextLink href={link}>
        <NextImage
          className={className}
          quality={quality}
          height={height}
          width={width}
          loader={loader}
          style={style}
          {...props}
        />
      </NextLink>
    );
  }

  return (
    <NextImage
      className={className}
      quality={quality}
      height={height}
      width={width}
      loader={loader}
      style={style}
      {...props}
    />
  );
};
