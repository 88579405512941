import clsx from 'clsx';
import Image from './Image';
import styles from './Thumbnail.module.scss';
import type { ContentfulImageConfig } from '@quno/patient-journey/src/utils/contentful';

type Props = {
  src: string;
  alt: string;
  size: number;
  shape: 'square' | 'circle';
  className?: string;
  focus?: ContentfulImageConfig['focus'];
  fit?: ContentfulImageConfig['fit'];
};

const Thumbnail = ({
  src,
  alt,
  size,
  shape,
  className,
  focus,
  fit,
}: Props): JSX.Element => {
  return (
    <div
      className={clsx(
        styles.container,
        shape === 'square' && styles.square,
        shape === 'circle' && styles.circle,
        className,
      )}
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
    >
      <Image
        className={styles.image}
        src={src}
        alt={alt}
        width={size}
        height={size}
        cloudimg
        priority
        focus={focus}
        fit={fit}
      />
    </div>
  );
};

export default Thumbnail;
