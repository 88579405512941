import config from '@quno/patient-journey/config';

/**
 * Check if given url string is for a external domain
 */
export function isExternalLink(link: unknown): boolean {
  if (!link) {
    throw new TypeError('Argument is required');
  }

  if (typeof link !== 'string') {
    throw new TypeError('Argument must be string');
  }

  const hasScheme = /^(\/\/)|([A-Za-z][A-Za-z0-9+.-]+:)/.test(link);
  const IS_HTTP_SCHEME_REGEX = /^((https?:\/\/)|\/\/)/;

  if (hasScheme && !IS_HTTP_SCHEME_REGEX.test(link)) {
    // Link contains a scheme that is not http or https,
    // consider it external
    return true;
  }

  const isDomainUrl = [
    ...Object.values(config.app.domains),
    ...Object.values(config.app.domains.proxy),
  ].reduce(
    (booleanAcc, domainName) =>
      booleanAcc ||
      (typeof domainName === 'string' &&
        new RegExp(`${escapeRegExp(domainName)}($|/)`).test(link)),
    false,
  );

  // -- If it has scheme and is within the domain, it is not external.
  // -- If it has scheme and is outside the domain, it is external.
  // -- If it has not a scheme, then it is a relative path
  return hasScheme ? !isDomainUrl : false;
}

/**
 * Escapes a string to be used as a RegExp
 *
 * @param text - string to be escaped
 */
function escapeRegExp(text: string): string {
  // $& means the whole matched string
  return text.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}
