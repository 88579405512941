import clsx from 'clsx';
import styles from './Card.module.scss';
import type { CardBaseProps } from './Card';
import type { CSSProperties } from 'react';

type Props = CardBaseProps & {
  /**
   * The alignment of the content inside the card
   * @default 'start'
   */
  alignment?: 'start' | 'center' | 'end';
  style?: CSSProperties;
};

export const CardContent = ({
  alignment = 'start',
  className,
  children,
  style,
}: Props): JSX.Element => {
  const alignmentConfig = {
    start: 'card-start',
    center: 'card-center',
    end: 'card-end',
  };

  return (
    <div
      style={style}
      className={clsx(
        styles['card-content'],
        styles[alignmentConfig[alignment]],
        className,
      )}
    >
      {children}
    </div>
  );
};
