import {
  Card as CardContainer,
  CardContent,
  CardFooter,
  CardMedia,
} from '@quno/qds/Card';
import clsx from 'clsx';
import styles from './BaseCard.module.scss';
import type { CardVariants } from './card.config';
import type { Orientation } from '@quno/qds/types/qds';
import type { MouseEventHandler, ReactNode } from 'react';

export type CardProps = {
  style?: React.CSSProperties;
  footer?: ReactNode;
  image?: JSX.Element | null;
  orientation?: Orientation;
  variant?: CardVariants;
  children?: ReactNode;
  mediaChildren?: ReactNode;
  mediaContentClassName?: string;
  full?: boolean;
  alignment?: 'start' | 'center' | 'end';
  contentAlignment?:
    | 'top left'
    | 'top center'
    | 'top right'
    | 'bottom left'
    | 'bottom center'
    | 'bottom right'
    | 'center'
    | 'center left'
    | 'center right';
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  backgroundColorOverwrite?: string;
  darkModeEnabled?: boolean;
  fontColorOverride?: string;
};

export const Card = ({
  style,
  footer,
  image,
  orientation = 'vertical',
  children,
  mediaChildren,
  mediaContentClassName,
  full = true,
  alignment = 'start',
  contentAlignment = 'top left',
  onClick,
  className,
  backgroundColorOverwrite,
  fontColorOverride,
}: CardProps): JSX.Element => {
  return (
    <CardContainer
      orientation={orientation}
      hoverable={false}
      className={clsx(styles.card, className)}
      onClick={onClick && onClick}
      style={{
        background: backgroundColorOverwrite || undefined,
        border: backgroundColorOverwrite ? 'none' : undefined,
        ...style,
      }}
    >
      {!!image && (
        <CardMedia
          className={styles[orientation]}
          full={full}
          image={image}
          contentAlignment={contentAlignment}
          contentClassName={mediaContentClassName}
        >
          {mediaChildren}
        </CardMedia>
      )}

      {children && (
        <CardContent
          className={clsx(styles.content)}
          alignment={alignment}
          style={{
            background: backgroundColorOverwrite || undefined,
            color: fontColorOverride || undefined,
          }}
        >
          {children}
          {footer && (
            <CardFooter className={styles.footer}>{footer}</CardFooter>
          )}
        </CardContent>
      )}
    </CardContainer>
  );
};
