import NextImage from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import { getOpacity } from '@quno/patient-journey/src/utils/opacity';
import { backgroundColorClass } from '@quno/patient-journey/src/theme/backgroundColorClass';
import styles from './Footer.module.scss';
import type { BackgroundColor } from '@quno/patient-journey/src/theme/backgroundColorClass';
import type { BlockType } from '@quno/patient-journey/src/contentful/mappers/mapRichTextToBlocks';

export type FooterProps = {
  cid: string;
  address?: React.ReactNode;
  contactNumber?: React.ReactNode;
  socialLinks?: BlockType;
  backgroundColor?: BackgroundColor;
  backgroundColorOpacity?: string;
  backgroundColorOverwrite?: string;
  darkModeEnabled?: boolean;
  qunosuitePoweredBadge?: boolean | null;
  fontColorOverride?: string | null;
};

export const Footer = ({
  cid,
  address,
  contactNumber,
  socialLinks,
  backgroundColor = 'primary',
  backgroundColorOpacity,
  backgroundColorOverwrite,
  qunosuitePoweredBadge,
  fontColorOverride,
}: FooterProps): JSX.Element => {
  const opacity = getOpacity(backgroundColorOpacity!);
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });

  return (
    <footer
      className={styles.footer}
      style={{ color: fontColorOverride || 'black' }}
    >
      <div
        className={clsx(
          styles.backgroundLayer,
          backgroundColorClass(backgroundColor),
        )}
        style={{
          backgroundColor: backgroundColorOverwrite || undefined,
          opacity: opacity || '1',
        }}
      />

      <div className={clsx(styles.footerContent)}>
        {!!address && (
          <div
            className={styles.address}
            {...inspectorProps({ fieldId: 'footerAddress' })}
          >
            {!!qunosuitePoweredBadge && (
              <div className={styles.qunosuitePoweredBadgeWrapper}>
                <div className={styles.qunosuitePoweredBadge}>
                  <div className={styles.qunosuitePoweredBadgeIcon}>
                    <svg
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="-6 0 40 28"
                    >
                      <path
                        d="M14.0636 0.00939941C6.29622 0.00939941 0 6.24692 0 13.9405C0 21.6342 6.29622 27.873 14.0636 27.873C21.8309 27.873 28.1272 21.6355 28.1272 13.9405C28.1272 6.24559 21.8296 0.00939941 14.0636 0.00939941ZM14.0568 22.6918C9.2601 22.6918 5.37034 18.8396 5.37034 14.0863C5.37034 9.33293 9.25875 5.48077 14.0568 5.48077C18.8549 5.48077 22.7433 9.33293 22.7433 14.0863C22.7433 18.8396 18.8549 22.6918 14.0568 22.6918Z"
                        fill="white"
                      />
                      <path
                        d="M26.8935 24.2574H23.5518C24.012 23.8697 24.4884 23.4004 24.9595 22.8321C27.1905 20.1392 28.3282 16.3178 28.1433 13.554C27.9571 10.7916 27.5859 7.70695 23.7515 3.81602C19.9211 -0.0695677 14.5009 -3.90672e-05 14.4901 -3.90672e-05C12.9528 -3.90672e-05 11.707 1.23409 11.707 2.7557C11.707 4.27731 12.861 5.41518 14.3173 5.50342V5.51947C14.3173 5.51947 18.1747 5.47401 20.7768 8.62686C23.2535 11.6273 22.8742 15.6252 22.1009 17.3059C20.8807 19.9627 19.0641 21.1942 18.2003 21.6568V15.5771C18.2003 14.5435 17.3541 13.7065 16.3121 13.7065C15.2702 13.7065 14.4239 14.5448 14.4239 15.5771V25.8526C14.4104 25.9422 14.401 26.0344 14.401 26.128C14.401 27.1616 15.2472 27.9986 16.2892 27.9986H26.8935C27.9368 27.9986 28.7817 27.1603 28.7817 26.128C28.7817 25.0958 27.9355 24.2574 26.8935 24.2574Z"
                        fill="#24C5BC"
                      />
                    </svg>
                  </div>
                  <div className={styles.qunosuitePoweredBadgeText}>
                    <span>powered by</span> <span>Qunosuite</span>
                  </div>
                </div>
              </div>
            )}
            <div>{address}</div>
          </div>
        )}

        {!!contactNumber && (
          <div
            className={styles.contactNumber}
            {...inspectorProps({ fieldId: 'footerContact' })}
          >
            {contactNumber}
          </div>
        )}

        {!!socialLinks && (
          <div
            className={styles.socialLinks}
            {...inspectorProps({ fieldId: 'footerSocialLinks' })}
          >
            <h4>{socialLinks?.h4}</h4>
            <div className={styles.socialLinkIcons}>
              {socialLinks?.assets?.map(
                ({ url, title, width, height }, index) => {
                  return (
                    <Link
                      href={socialLinks?.hyperlinks?.[index]?.href as string}
                      key={index}
                      target="_blank"
                    >
                      <NextImage
                        src={url || ''}
                        width={width || 0}
                        height={height || 0}
                        alt={title || ''}
                        loader={createCloudimgLoader()}
                      />
                    </Link>
                  );
                },
              )}
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};
