import clsx from 'clsx';
import NextImage from 'next/image';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import styles from './Image.module.scss';
import type { ImageProps } from 'next/image';
import type { ContentfulImageConfig } from '@quno/patient-journey/src/utils/contentful';

type Props = ImageProps &
  ContentfulImageConfig & {
    cloudimg?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any;
  };

const Image = ({
  cloudimg = false,
  width,
  height,
  fit,
  focus,
  src,
  quality = 75,
  isSvg,
  className,
  ...props
}: Props): JSX.Element | null => {
  if (!src) {
    return null;
  }

  const contentfulAsset =
    cloudimg &&
    /^(?:http:|https:)?\/\/images\.ctfassets\.net/.test(src as string);

  return (
    <NextImage
      className={clsx(cloudimg && styles.image, className)}
      loader={
        contentfulAsset
          ? createCloudimgLoader({ fit, focus, isSvg, height })
          : undefined
      }
      src={src}
      width={width}
      height={height}
      quality={quality}
      {...props}
    />
  );
};

export default Image;
