import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import type { Asset, Entry } from '../generated/graphql';
import type { Text, Block, Inline } from '@contentful/rich-text-types';

export type HyperlinkType = {
  href: string;
  text: string;
};

export type BlockType = {
  asset?: Asset;
  assets?: Asset[];
  entry?: Entry;
  entries?: Entry[];
  h2?: string;
  h3?: string;
  h4?: string;
  h5?: string;
  h6?: string;
  p?: string;
  hyperlink?: HyperlinkType;
  hyperlinks?: HyperlinkType[];
};

export const getIdFromFunnelUrn = (urn: string): string => {
  const index = urn.lastIndexOf('/');
  const funnelId = urn.substring(index + 1);
  return funnelId;
};

export const mapRichTextToBlocks = <
  T extends
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        json?: any;
        links?: {
          assets?: {
            block: ({
              sys: { id: string };
              url?: string | null;
              width?: number | null;
              height?: number | null;
              title?: string | null;
              description?: string | null;
            } | null)[];
          };
          entries?: {
            block?: ({
              sys: { id: string };
            } | null)[];
            inline?: ({
              sys: { id: string };
            } | null)[];
          };
        };
      }
    | undefined
    | null,
>(
  richTextContent: T,
): BlockType[] => {
  const elements: BlockType[] = [];
  let assets: Asset[] = [];
  let entries: Entry[] = [];
  let hyperlinks: HyperlinkType[] = [];
  let element: BlockType = {};

  if (richTextContent?.json) {
    for (const node of richTextContent?.json?.content as (Block | Inline)[]) {
      if (node.nodeType === BLOCKS.HEADING_2) {
        element.h2 = (node.content[0] as Text)?.value;
      }

      if (node.nodeType === BLOCKS.HEADING_3) {
        element.h3 = (node.content[0] as Text)?.value;
      }

      if (node.nodeType === BLOCKS.HEADING_4) {
        element.h4 = (node.content[0] as Text)?.value;
      }

      if (node.nodeType === BLOCKS.HEADING_5) {
        element.h5 = (node.content[0] as Text)?.value;
      }

      if (node.nodeType === BLOCKS.HEADING_6) {
        element.h6 = (node.content[0] as Text)?.value;
      }

      if (node.nodeType === BLOCKS.PARAGRAPH && !element.p) {
        element.p = (node.content[0] as Text)?.value;
      }

      if (node.nodeType === BLOCKS.PARAGRAPH) {
        const link = node.content.find(
          (content) => content.nodeType === INLINES.HYPERLINK,
        );

        if (link) {
          const hyperlink = {
            text: ((link as Block).content[0] as Text)?.value,
            href: link.data.uri,
          };

          hyperlinks.push(hyperlink);
          element.hyperlink = hyperlink;
        }

        if (node.content.length) {
          for (const subNode of node.content) {
            if (subNode.nodeType === BLOCKS.EMBEDDED_ENTRY) {
              const entry = richTextContent?.links?.entries?.block?.find(
                (block) => block?.sys?.id === subNode?.data?.target?.sys?.id,
              ) as Entry;

              // eslint-disable-next-line
              // @ts-ignore
              if (entry && entry.action === 'funnel' && entry.funnel.sys.urn) {
                // eslint-disable-next-line
                // @ts-ignore
                entry.funnelId = getIdFromFunnelUrn(entry.funnel.sys.urn);
              }

              entries.push(entry);
            }

            if (subNode.nodeType === INLINES.EMBEDDED_ENTRY) {
              const entry = richTextContent?.links?.entries?.inline?.find(
                (block) => block?.sys?.id === subNode?.data?.target?.sys?.id,
              ) as Entry;

              // eslint-disable-next-line
              // @ts-ignore
              if (entry && entry.action === 'funnel' && entry.funnel.sys.urn) {
                // eslint-disable-next-line
                // @ts-ignore
                entry.funnelId = getIdFromFunnelUrn(entry.funnel.sys.urn);
              }

              entries.push(entry);
            }
          }
        }
      }

      if (node.nodeType === BLOCKS.EMBEDDED_ASSET) {
        const asset = richTextContent?.links?.assets?.block.find(
          (block) => block?.sys?.id === node?.data?.target?.sys?.id,
        ) as Asset;

        assets.push(asset);
        element.asset = asset;
      }

      if (node.nodeType === BLOCKS.HR) {
        elements.push({ ...element, assets, entries, hyperlinks });
        element = {};
        assets = [];
        entries = [];
        hyperlinks = [];
      }
    }

    elements.push({ ...element, assets, entries, hyperlinks });
    element = {};
    assets = [];
    entries = [];
    hyperlinks = [];
  }

  return elements;
};
