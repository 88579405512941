import {
  useContentfulInspectorMode,
  useContentfulLiveUpdates,
} from '@contentful/live-preview/react';
import { BeforeAfterPhotoCard } from '@quno/patient-journey/src/components/Card/Variants/BeforeAfterPhotoCard';
import { DoctorCard } from '@quno/patient-journey/src/components/Card/Variants/DoctorCard';
import { TextCard } from '@quno/patient-journey/src/components/Card/Variants/TextCard';
import { LogosCard } from '@quno/patient-journey/src/components/Card/Variants/LogosCard';
import { PhotoCard } from '@quno/patient-journey/src/components/Card/Variants/PhotoCard';
import { ProductCard } from '@quno/patient-journey/src/components/Card/Variants/ProductCard';
import { ImageCard } from '@quno/patient-journey/src/components/Card/Variants/ImageCard';
import { ReviewCard } from '@quno/patient-journey/src/components/Card/Variants/ReviewCard';
import { Card } from '@quno/patient-journey/src/components/Card/Variants/Card';
import { CARD_IMAGE_MAX_WIDTH } from '@quno/patient-journey/src/constants/image';
import { TreatmentCard } from '@quno/patient-journey/src/components/Card/Variants/TreatmentCard';
import { Cta } from '@quno/patient-journey/src/components/Cta/Cta';
import { renderImage } from '../renderImage';
import type { Packages } from '@quno/patient-journey/src/contentful/generated/graphql';
import type {
  CardProps,
  CardVariants,
} from '@quno/patient-journey/src/components/Card/card.config';
import type { BlockCardFragment } from '../../generated/graphql-patient-journey';

export type Package = Omit<Packages, 'sys'> & {
  sys: { id: string };
};

export const BlockCard = (props: BlockCardFragment): JSX.Element | null => {
  const updatedEntity = useContentfulLiveUpdates(props);
  const inspectorProps = useContentfulInspectorMode({
    entryId: props.sys.id,
  });

  const variant = updatedEntity.variant as CardVariants;
  const textAlignment = updatedEntity.textAlignment as
    | 'left'
    | 'center'
    | 'right';

  const {
    primaryImage,
    secondaryImage,
    cta,
    eventSetupId,
    funnelId,
    reviewRating,
    reviewDate,
    darkModeEnabled,
    backgroundColorOverwrite,
    title,
    description,
    footer,
    primaryImageBorderRadius,
    fontColorOverride,
    imageStyle,
    imagePosition,
    cardBorderRadius,
  } = updatedEntity;

  const cardProps = {
    cid: props.sys.id,
    darkModeEnabled: darkModeEnabled || false,
    backgroundColorOverwrite:
      backgroundColorOverwrite as CardProps['backgroundColorOverwrite'],
    fontColorOverride: fontColorOverride as CardProps['fontColorOverride'],
    imageStyle: imageStyle as CardProps['imageStyle'],
    imagePosition: imagePosition as CardProps['imagePosition'],
    cardBorderRadius: cardBorderRadius as CardProps['cardBorderRadius'],
  };

  const renderedPrimaryImage =
    primaryImage && primaryImage.url
      ? renderImage(primaryImage, {
          container: true,
          maxWidth: CARD_IMAGE_MAX_WIDTH,
          fit: variant === 'doctor' ? 'thumb' : undefined,
          focus: variant === 'doctor' ? 'face' : undefined,
          style: { borderRadius: primaryImageBorderRadius || 0 },
        })
      : null;

  const renderedSecondaryImage = secondaryImage
    ? renderImage(secondaryImage, {
        container: true,
        maxWidth: CARD_IMAGE_MAX_WIDTH,
      })
    : null;

  if (variant === 'doctor') {
    return (
      <DoctorCard
        image={renderedPrimaryImage}
        title={title}
        text={description?.json}
        {...cardProps}
      />
    );
  }

  if (variant === 'before_after_photos') {
    return (
      <BeforeAfterPhotoCard
        beforePhoto={renderedPrimaryImage}
        afterPhoto={renderedSecondaryImage}
        {...cardProps}
      />
    );
  }

  if (variant === 'photo') {
    return (
      <PhotoCard
        image={renderedPrimaryImage}
        title={title}
        text={description?.json}
        {...cardProps}
      />
    );
  }

  if (variant === 'text') {
    return (
      <TextCard
        text={description?.json}
        alignment={textAlignment}
        {...cardProps}
      />
    );
  }

  if (variant === 'image') {
    return (
      <ImageCard
        image={renderedPrimaryImage}
        borderRadius={primaryImageBorderRadius}
        {...cardProps}
      />
    );
  }

  if (variant === 'logos') {
    return (
      <LogosCard>
        <div {...inspectorProps({ fieldId: 'primaryImage' })}>
          {renderedPrimaryImage}
        </div>
      </LogosCard>
    );
  }

  if (variant === 'review') {
    return (
      <ReviewCard
        rating={reviewRating}
        date={reviewDate}
        title={title}
        footer={footer}
        text={description?.json}
        {...cardProps}
      />
    );
  }

  if (variant === 'product') {
    return (
      <ProductCard
        image={renderedPrimaryImage}
        title={title}
        footer={footer}
        text={description?.json}
        {...cardProps}
      />
    );
  }

  if (variant === 'card') {
    return (
      <Card
        image={renderedPrimaryImage}
        title={title}
        footer={footer}
        text={description?.json}
        {...cardProps}
      />
    );
  }

  if (variant === 'treatment') {
    const { fields, sys, includes } =
      (updatedEntity.package as unknown as {
        fields: Packages;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        includes: { Asset: any[] };
        sys: { urn: string };
      }) || {};

    const index = sys?.urn?.lastIndexOf('/');
    const contentfulPackageId = sys?.urn?.substring(index + 1);

    const contentfulPackage: Package = {
      ...fields,
      sys: { id: contentfulPackageId },
    };

    const {
      name,
      description,
      price,
      startingFromPrice,
      image: imageLink,
      duration,
    } = fields || {};

    const image = includes?.Asset.find(
      (asset: { sys: { id: string } }) => asset.sys.id === imageLink?.sys.id,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const photoMapper = (image: any): any => {
      return {
        width: image.fields.file.details.image.width,
        height: image.fields.file.details.image.height,
        url: image.fields.file.url,
        title: image.fields.title,
      };
    };

    const packageImage = image
      ? renderImage(photoMapper(image), {
          container: true,
          fill: true,
          style: { borderRadius: primaryImageBorderRadius || 0 },
        })
      : null;

    const bookNowCta = cta ? (
      <Cta
        {...cta}
        eventSetupId={eventSetupId}
        funnelId={funnelId}
        contentfulPackage={contentfulPackage}
      />
    ) : null;

    return (
      <TreatmentCard
        name={name}
        image={packageImage}
        description={description}
        price={price}
        duration={duration}
        isFrom={startingFromPrice}
        cta={bookNowCta}
        {...cardProps}
      />
    );
  }

  return null;
};
