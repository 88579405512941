import clsx from 'clsx';
import Image from 'next/image';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { backgroundColorClass } from '@quno/patient-journey/src/theme/backgroundColorClass';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import { getOpacity } from '@quno/patient-journey/src/utils/opacity';
import RichText from '../ui/ContentBlocks/RichText';
import { Cta } from '../Cta/Cta';
import styles from './HeroSimple.module.scss';
import type { CtaProps } from '../Cta/Cta';
import type { HeroProps } from './hero.config';

export type HeroSimpleProps = HeroProps & {
  ctas?: (CtaProps & { funnelId?: string })[];
};

export const HeroSimple = ({
  cid,
  text,
  image,
  imageDetailPosition,
  centerContent,
  flipContent,
  backgroundColor = 'default',
  cardOnMobile,
  backgroundColorOverwrite,
  backgroundColorOpacity,
  ctas,
  imageBorderRadius,
  fontColorOverride,
}: HeroSimpleProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });
  const opacity = getOpacity(backgroundColorOpacity!);

  const textSection = (
    <div
      key="text"
      className={clsx(
        styles.textSection,
        centerContent && styles.centerContent,
      )}
      style={{ color: fontColorOverride || 'black' }}
      {...inspectorProps({ fieldId: 'textContent' })}
    >
      <RichText
        fields={text}
        customStyle={styles.customRichTextStyles}
        style={
          {
            color: fontColorOverride,
            '--list-style-color': fontColorOverride,
          } as React.CSSProperties
        }
      />

      {!!ctas?.length && (
        <div
          className={clsx(styles.ctas, centerContent && styles.centerContent)}
        >
          {ctas.map((cta, i) => (
            <Cta key={i} {...cta} />
          ))}
        </div>
      )}
    </div>
  );

  const imageSection = image && (
    <div key="image" className={styles.imageSection}>
      <Image
        {...image}
        loader={createCloudimgLoader()}
        {...inspectorProps({ fieldId: 'image' })}
        style={{
          objectPosition: imageDetailPosition || 'center',
          borderRadius: imageBorderRadius,
        }}
      />
    </div>
  );

  const sections = [textSection, imageSection];

  return (
    <div
      className={clsx(
        styles.container,
        cardOnMobile && styles.cardOnMobile,
        backgroundColorClass(backgroundColor),
      )}
      style={{
        backgroundColor: backgroundColorOverwrite || undefined,
        opacity: opacity || '1',
      }}
    >
      <div
        className={clsx(styles.wrapper, imageSection && styles.heroWithImage)}
      >
        {!flipContent ? sections : sections.reverse()}
      </div>
    </div>
  );
};
