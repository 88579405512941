import React from 'react';

export type PatientContextProps = {
  junoId: string;
  firstName: string;
  lastName: string;
};

export type PatientContext = PatientContextProps | null;

type PatientContextType = {
  setPatient: (patient: PatientContext | null) => void;
  patient: PatientContext | null;
};

export const PatientContext = React.createContext<PatientContextType>({
  patient: null,
  setPatient() {
    return;
  },
});
