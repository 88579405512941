import Head from 'next/head';
import type { Faq } from '@quno/patient-journey/types/Utilities';

type Props = {
  items: Faq[];
};

type Question = {
  '@type': string;
  name: string;
  acceptedAnswer: { '@type': string; text: string };
};

export const FaqJsonSchema = ({ items }: Props): JSX.Element | null => {
  if (!items.length) {
    return null;
  }

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [] as Question[],
  };

  for (const item of items) {
    schema.mainEntity.push({
      '@type': 'Question',
      name: item.question || '',
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.answer || '',
      },
    });
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Head>
  );
};
