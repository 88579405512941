import { Card } from '@quno/patient-journey/src/components/Card/Card';
import styles from './LogosCard.module.scss';
import type { ReactNode } from 'react';
import type { CardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type LogosCardProps = {
  children: ReactNode;
};

export const LogosCard = ({
  children,
}: CardProps & LogosCardProps): JSX.Element => {
  return (
    <Card className={styles.card} alignment="center">
      {children}
    </Card>
  );
};
