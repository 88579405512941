import { getContentfulLocale } from '@quno/patient-journey/src/utils/locale';
import { fetchContentfulApi } from '@quno/patient-journey/src/contentful/quno-next/fetchContentfulApi';
import type { Employee } from '@quno/patient-journey/types/Employee';

export const findEmployee = async (
  locale: string,
  salesforceUserId: string,
): Promise<Employee | null> => {
  const contentfulLocale = getContentfulLocale(locale);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gqResponse = await fetchContentfulApi<any, unknown>({
    query: 'GET_EMPLOYEE',
    variables: {
      locale: contentfulLocale,
      salesforceUserId,
    },
  });

  return gqResponse.data.employeeCollection.items[0];
};
