import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import clsx from 'clsx';
import styles from './ImageCard.module.scss';
import type { CardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type ImageCardProps = {
  cid: string;
  image?: JSX.Element | null;
  borderRadius?: string | null;
};

export const ImageCard = ({
  cid,
  image,
  borderRadius = 'none',
}: CardProps & ImageCardProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.imageWrapper, styles[borderRadius as string])}
        {...inspectorProps({ fieldId: 'primaryImage' })}
      >
        {image}
      </div>
    </div>
  );
};
