import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { Accordion } from '@quno/patient-journey/src/components/Accordion/Accordion';
import { FaqJsonSchema } from '@quno/patient-journey/src/components/Accordion/FaqJsonSchema';
import { renderArrayBlocks } from '../renderBlocks';
import type { BlockAccordionFragment } from '../../generated/graphql-patient-journey';
import type { Faq } from '@quno/patient-journey/types/Utilities';

export const BlockAccordion = (props: BlockAccordionFragment): JSX.Element => {
  const updatedEntity = useContentfulLiveUpdates(props);

  const { itemsCollection, isFaq } = updatedEntity;

  const items = renderArrayBlocks(itemsCollection?.items);

  const faqItems: Faq[] = [];
  if (isFaq) {
    for (const item of itemsCollection?.items || []) {
      if (!item || item.disableLocale || !item.content) {
        continue;
      }

      faqItems.push({
        question: item.title!,
        answer: documentToHtmlString(item.content.json),
      });
    }
  }

  return (
    <>
      {props.isFaq && <FaqJsonSchema items={faqItems} />}
      <Accordion>{items}</Accordion>
    </>
  );
};
