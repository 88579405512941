/* eslint-disable-next-line no-restricted-imports */
import NextLink from 'next/link';
import { cloneElement, isValidElement } from 'react';
import { isExternalLink } from '@quno/patient-journey/src/utils/isExternalLink';
/* eslint-disable-next-line no-restricted-imports */
import type { LinkProps } from 'next/link';

type Props = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof LinkProps
> &
  Omit<LinkProps, 'href'> & {
    href: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } & { children: any };

const Link = ({
  href,
  rel,
  children,
  legacyBehavior = true,
  ...props
}: Props): JSX.Element => {
  if (
    isExternalLink(href) &&
    isValidElement<HTMLAnchorElement>(children) &&
    legacyBehavior
  ) {
    const rel = ['noopener', children?.props.rel].filter(Boolean).join(' ');
    return cloneElement(children, {
      href,
      rel,
    });
  }

  let relAttribute = rel;
  if (isExternalLink(href)) {
    relAttribute = ['noopener', rel].filter(Boolean).join(' ');
  }

  return (
    <NextLink
      href={href}
      rel={relAttribute}
      legacyBehavior={legacyBehavior}
      {...props}
    >
      {children}
    </NextLink>
  );
};

export default Link;
