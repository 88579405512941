import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { HeroSimple } from '@quno/patient-journey/src/components/Hero/HeroSimple';
import { HeroFunnel } from '@quno/patient-journey/src/components/Hero/HeroFunnel';
import { mapRichTextToBlocks } from '@quno/patient-journey/src/contentful/mappers/mapRichTextToBlocks';
import type {
  BlockHeroFragment,
  CtaFragment,
} from '../../generated/graphql-patient-journey';
import type { BackgroundColor } from '@quno/patient-journey/src/theme/backgroundColorClass';
import type { BackgroundStyle } from '@quno/patient-journey/src/theme/backgroundStyleClass';
import type { HeroProps } from '@quno/patient-journey/src/components/Hero/hero.config';

export type BlockHeroVariant = 'simple' | 'funnel';

export const BlockHero = (
  props: BlockHeroFragment & { variant: BlockHeroVariant },
): JSX.Element => {
  const updatedEntity = useContentfulLiveUpdates(props);

  const {
    variant,
    imageDetailPosition,
    centerContent,
    flipContent,
    darkModeEnabled,
    backgroundColor,
    backgroundColorOpacity,
    backgroundColorOverwrite,
    backgroundStyle,
    backgroundRepeat,
    funnel,
    cardOnMobile,
    image,
    textContent,
    imageBorderRadius,
    fontColorOverride,
  } = updatedEntity;

  const imageAttrs = image
    ? {
        src: image.url!,
        alt: image.title!,
        width: image.width!,
        height: image.height!,
      }
    : undefined;

  const funnelUrn = funnel?.sys.urn;

  const heroBaseProps = {
    cid: props.sys.id,
    backgroundRepeat: !!backgroundRepeat,
    backgroundColor: backgroundColor as BackgroundColor,
    backgroundColorOpacity:
      backgroundColorOpacity as HeroProps['backgroundColorOpacity'],
    backgroundColorOverwrite:
      backgroundColorOverwrite as HeroProps['backgroundColorOverwrite'],
    darkModeEnabled: !!darkModeEnabled,
    flipContent: !!flipContent,
    centerContent: !!centerContent,
    image: imageAttrs,
    text: textContent?.json,
    imageBorderRadius: imageBorderRadius as HeroProps['imageBorderRadius'],
    fontColorOverride: fontColorOverride as HeroProps['fontColorOverride'],
  };

  const [cta] = mapRichTextToBlocks(textContent);

  if (variant === 'funnel' && funnelUrn) {
    const index = funnelUrn.lastIndexOf('/');
    const funnelId = funnelUrn.substring(index + 1);

    return (
      <HeroFunnel
        funnelId={funnelId}
        ctas={cta?.entries as unknown as CtaFragment[]}
        backgroundStyle={backgroundStyle as BackgroundStyle}
        {...heroBaseProps}
      />
    );
  }

  return (
    <HeroSimple
      cardOnMobile={cardOnMobile}
      ctas={cta?.entries as unknown as CtaFragment[]}
      imageDetailPosition={
        imageDetailPosition as HeroProps['imageDetailPosition']
      }
      {...heroBaseProps}
    />
  );
};
