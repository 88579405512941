type BlockType = { __typename?: string };

export const makeRenderBlocks = (
  blocksMapper: Record<string, BlockType>,
): {
  renderBlock: (
    blocks: BlockType | null | undefined,
    blockOverwrite?: object,
  ) => JSX.Element;
  renderBlocks: (
    blocks?: (BlockType | null | undefined)[],
    blockOverwrite?: object,
  ) => JSX.Element;
  renderArrayBlocks: (
    blocks?: (BlockType | null | undefined)[],
    blockOverwrite?: object,
  ) => JSX.Element[];
} => {
  const renderBlock = (
    blocks: BlockType | null | undefined,
    blockOverwrite?: object,
  ): JSX.Element => {
    if (Array.isArray(blocks)) {
      return renderBlocks(blocks, blockOverwrite);
    }

    return renderBlocks([blocks], blockOverwrite);
  };

  const renderBlocks = (
    blocks?: (BlockType | null | undefined)[],
    blockOverwrite?: object,
  ): JSX.Element => {
    return <>{renderArrayBlocks(blocks, blockOverwrite)}</>;
  };

  const renderArrayBlocks = (
    blocks?: (BlockType | null | undefined)[],
    blockOverwrite?: object,
  ): JSX.Element[] => {
    return (
      (blocks?.map((block, id) => {
        if (block?.__typename) {
          const Block = blocksMapper[block?.__typename as 'Page'];

          if (Block) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <Block key={id} {...block} {...blockOverwrite} />;
          }
        }
      }) as JSX.Element[]) || []
    );
  };

  return {
    renderBlock,
    renderBlocks,
    renderArrayBlocks,
  };
};
