import { Page } from './blocks/Page';
import { BlockHero } from './blocks/BlockHero';
import { BlockContainer } from './blocks/BlockContainer';
import { BlockCard } from './blocks/BlockCard';
import { BlockCalendarBooking } from './blocks/BlockCalendarBooking';

import { BlockCta } from './blocks/BlockCta';
import { BlockAccordion } from './blocks/BlockAccordion';
import { BlockAccordionItem } from './blocks/BlockAccordionItem';
import type {
  BlockHeroFragment,
  BlockContainerFragment,
  BlockCardFragment,
  BlockCalendarBookingFragment,
  CtaFragment,
  BlockAccordionFragment,
  BlockAccordionItemFragment,
} from '../generated/graphql-patient-journey';
import type { BasePageFragmentFragment } from '@quno/quno-gen';

export type BlockTypes =
  | BasePageFragmentFragment
  | BlockHeroFragment
  | BlockContainerFragment
  | BlockCardFragment
  | BlockCalendarBookingFragment
  | BlockAccordionFragment
  | BlockAccordionItemFragment
  | CtaFragment;

export const blocksMapper: Record<
  BlockTypes['__typename'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> = {
  Page,
  BlockHero,
  BlockContainer,
  BlockCard,
  BlockCalendarBooking,
  BlockAccordion,
  BlockAccordionItem,
  Cta: BlockCta,
};
