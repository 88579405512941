import clsx from 'clsx';
import styles from './Card.module.scss';
import type { CardBaseProps } from './Card';
import type { ReactNode } from 'react';

type Props = CardBaseProps & {
  /**
   * The alt text for the image
   * @default ''
   */
  alt?: string;
  /**
   * The alignment of the content
   * @default 'top left'
   */
  contentAlignment?:
    | 'top left'
    | 'top center'
    | 'top right'
    | 'bottom left'
    | 'bottom center'
    | 'bottom right'
    | 'center'
    | 'center left'
    | 'center right';
  /**
   * Classname applied to content wrapper
   */
  contentClassName?: string;
  /**
   * If `true`, the image will use the entire container
   * @default true
   */
  cover?: boolean;
  /**
   * The image to be shown. It can be a url or also a component.
   */
  image: string | ReactNode;
  /**
   * If `true`, the image is faded.
   * @default false
   */
  opacity?: boolean;
};

export const CardMedia = ({
  alt = '',
  children,
  contentAlignment = 'top left',
  contentClassName,
  cover = true,
  full,
  image,
  opacity,
  className,
}: Props): JSX.Element => {
  const alignmentConfig = {
    'top left': 'card-media-content-tl',
    'top center': 'card-media-content-tc',
    'top right': 'card-media-content-tr',
    'bottom left': 'card-media-content-bl',
    'bottom center': 'card-media-content-bc',
    'bottom right': 'card-media-content-br',
    center: 'card-media-content-c',
    'center left': 'card-media-content-cl',
    'center right': 'card-media-content-cr',
  };

  return (
    <div
      className={clsx(
        styles['card-media'],
        full && styles['card-full-image'],
        opacity && styles['card-opacity'],
        cover && styles['card-cover'],
        className,
      )}
    >
      {typeof image === 'string' ? (
        <figure>
          <img src={image} alt={alt} />
        </figure>
      ) : (
        image
      )}
      {!full && children && (
        <div
          className={clsx(
            styles['card-media-content'],
            styles[alignmentConfig[contentAlignment]],
            contentClassName,
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};
