import { BLOCKS } from '@contentful/rich-text-types';
import type { Block, Inline } from '@contentful/rich-text-types';
import type { ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const richTextLineBreakConfig: any = {
  renderNode: {
    [BLOCKS.PARAGRAPH](_node: Block | Inline, children: ReactNode) {
      if (children?.toString() === '') {
        return <br />;
      }

      return <p>{children}</p>;
    },
  },
};
